<template>
    <LayoutNext>
        <template #page-title>
            User Profile
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><a @click.prevent="goBack" class="text-primary" style="cursor: default;">Back to
                        previous</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    User Profile
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
            </div>
        </div>
        <div class="d-block d-md-flex gap-3">
            <div class="w-100">
                <MDBCard class="shadow-sm w-100 mb-3">
                    <MDBCardBody class="d-block d-md-flex gap-3 align-items-center">
                        <div class="d-flex gap-3 align-items-center mb-md-0 mb-4">
                            <div class="default-avatar shadow-lg me-1" v-if="!usersStore.users.profilePictureUrl">
                                <span class="avatar-letter">{{ avatarLetter }}</span>
                            </div>
                            <img v-else :src="usersStore.users.profilePictureUrl" alt="Profile Picture"
                                class="profile-avatar shadow-lg me-1" />
                            <div class="text-wrap">
                                <h6 class="fw-bold my-1 text-break">{{ usersStore.users.firstName }} {{ usersStore.users.lastName
                                    }}</h6>
                                <span class="align-bottom text-break">{{ usersStore.users.email }}</span>
                            </div>
                        </div>
                        <div class="d-flex gap-2 ms-auto">
                            <input type="file" accept="image/*" @change="onFileChange" ref="fileInput"
                                style="display: none;" />
                            <MDBBtn :class="usersStore.users.profilePictureUrl ? 'd-none fw-bold' : 'd-block fw-bold'"
                                color="primary" size="sm" @click="openFileDialog">
                                <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                                Set a profile picture
                            </MDBBtn>
                            <MDBBtn :class="usersStore.users.profilePictureUrl ? 'd-block fw-bold' : 'd-none fw-bold'"
                                size="sm" @click="openFileDialog">
                                <MDBIcon iconStyle="fas" icon="pen" class="me-1" />
                                Edit my profile picture
                            </MDBBtn>
                        </div>
                    </MDBCardBody>
                </MDBCard>
                <div :class="isUser ? 'd-block' : 'd-block d-md-flex gap-3 h-75'">
                    <MDBCard :class="isUser ? 'shadow-sm w-100 mb-3' : 'shadow-sm w-100 mb-3 mb-md-0'">
                        <MDBCardHeader class="py-3">
                            <h5 class="mb-0 fw-bold">Account Information</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <div class="d-flex gap-2">
                                <h6 class="fw-bold mb-0">Client Type: </h6>
                                <h6 class="mb-0 ms-auto">{{ userType(usersStore.users.userTypeId) }}</h6>
                            </div>
                            <div class="d-flex gap-2 mt-3">
                                <h6 class="fw-bold mb-0">Join Date: </h6>
                                <h6 class="mb-0 ms-auto">{{ parseDateLong(usersStore.users.activationDate) }}</h6>
                            </div>
                            <div class="d-flex gap-2 mt-3">
                                <h6 class="fw-bold mb-0">Account Status: </h6>
                                <h6 class="mb-0 ms-auto">{{ usersStore.users.isActive ? 'Active' : 'Inactive' }}</h6>
                            </div>
                            <div class="d-flex gap-2 mt-3">
                                <h6 class="fw-bold mb-0">Organization Relationship: </h6>
                                <h6 class="mb-0 ms-auto">N/A</h6>
                            </div>
                        </MDBCardBody>
                        <MDBCardFooter class="d-flex justify-content-start align-items-center">
                            <MDBBtn tag="a" color="link" size="sm"
                                href="mailto:requests@enfinity.com?subject=I am requesting to change my payment information"
                                class="fw-bold text-capitalize px-0 text-left">
                                Contact us to update your payment information.
                            </MDBBtn>
                        </MDBCardFooter>
                    </MDBCard>
                    <MDBCard class="shadow-sm w-100 mb-3 mb-md-0">
                        <MDBCardHeader class="py-3">
                            <h5 class="mb-0 fw-bold">Account Settings</h5>
                        </MDBCardHeader>
                        <MDBCardBody class="px-2">
                            <MDBContainer fluid tag="form">
                                <h6 class="fw-bold">Update Password</h6>
                                <MDBCol class="mt-3">
                                    <MDBInput label="New Password" type="password" v-model="form.password" />
                                </MDBCol>
                                <MDBCol class="mb-2">
                                    <password-meter @score="onScore" :password="form.password" />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput label="Confirm Password" type="password" v-model="confirmPassword"
                                        :helper="form.password && confirmPassword && !isSamePassword
                                            ? 'Password does not match!'
                                            : ''
                                            " />
                                </MDBCol>
                            </MDBContainer>
                        </MDBCardBody>
                        <MDBCardFooter class="d-flex justify-content-end align-items-center">
                            <MDBBtn @click="submit" size="sm" color="primary" :disabled="isSubmitBtnDisabled"
                                class="fw-bold text-uppercase text-nowrap">
                                Save
                            </MDBBtn>
                        </MDBCardFooter>
                    </MDBCard>
                </div>
            </div>
            <MDBCard v-if="isUser" class="shadow-sm social-card">
                <MDBCardHeader class="py-3">
                    <h5 class="mb-0 fw-bold text-center">Protected Content</h5>
                </MDBCardHeader>
                <MDBCardBody class="pb-0">
                    <MDBTable borderless style="table-layout: fixed;">
                        <tbody v-for="media in socialMedia" :key="media.value">
                            <tr>
                                <td class="py-3 ps-0 align-middle social-title">
                                    <div class="d-flex align-items-center">
                                        <template v-if="media.value == 8">
                                            <img src="@/assets/kuashou-icon.png" alt="Kuaishou"
                                                style="width: 16px; height: 16px; margin-right:12px;" />
                                        </template>
                                        <template v-else-if="media.value == 9">
                                            <img src="@/assets/rumble-icon.png" alt="Rumble"
                                                style="width: 16px; height: 16px; margin-right:12px;" />
                                        </template>
                                        <MDBIcon v-else :icon="media.icon" :iconStyle="media.iconStyle"
                                            :style="media.marginRight" class="fa-md pt-1"
                                            style="width: 20px; height: 20px; text-align: center;" />
                                        <label :for="`${media.text}Link`" class="fw-bold">{{ media.text }}</label>
                                    </div>
                                </td>
                                <td class="py-3 pe-0 align-middle social-link">
                                    <span class="text-truncate d-inline-block align-middle"
                                        v-if="socialMediaLinks[media.value]"
                                        style="max-width:100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                        <a :href="`//${socialMediaLinks[media.value]}`"
                                            :title="socialMediaLinks[media.value]" target="_blank">
                                            {{ socialMediaLinks[media.value] || 'page url' }}
                                        </a>
                                    </span>
                                    <span v-else class="text-truncate d-inline-block align-middle"
                                        style="max-width:100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">No
                                        link provided.</span>
                                </td>
                            </tr>
                        </tbody>
                    </MDBTable>
                </MDBCardBody>
                <MDBCardFooter class="d-flex justify-content-center">
                    <MDBBtn class="fw-bold" size="sm" color="primary" @click="sendEmail">
                        Request Content Update
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        </div>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import { MDBCol, MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBInput, MDBToast, MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer, MDBIcon, MDBTable } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { computed, reactive, ref, onMounted, watch } from "vue";
import axios from "axios";
import PasswordMeter from "vue-simple-password-meter";
import { useTitle } from "@vueuse/core";
import { useRouter } from 'vue-router';
import { parseDateLong } from '@/helpers/parseDate';
import { useUsersStore } from "@/store/usersStore";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";

useTitle("User Account | Creator Shields");

const usersStore = useUsersStore();
const { isUser } = storeToRefs(usersStore);

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);

onMounted( () => {
    watch(userDetail, async (newUserDetail) => {
        await usersStore.getUsers({ UserId: userDetailStore.userDetail.id });
        useTitle(`${newUserDetail.firstName}'s Profile | Very Viral Visuals`);
    });
});


const form = reactive({
    password: "",
});

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
    title: "",
});

let loadingSpinner = ref(false);
const submit = () => {
    let formData = new FormData();
    formData.append("password", form.password);
    loadingSpinner.value = true;

    axios
        .put("api/users/updateSettings", formData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(function (response) {
            if (response.status == 200) {
                toastObject.value.title = "Success!";
                toastObject.value.state = true;
                toastObject.value.message = "Successfully submitted!";
                toastObject.value.color = "success";
                toastObject.value.icon = "fas fa-check fa-lg me-2";
                form.password = "";
                confirmPassword.value = "";
                loadingSpinner.value = false;
            } else {
                toastObject.value.title = "Error!";
                toastObject.value.state = true;
                toastObject.value.message = "An error occurred during request.";
                toastObject.value.color = "danger";
                toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                loadingSpinner.value = false;
            }
        })
        .catch(function (error) {
            toastObject.value.title = "Error!";
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request: " + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
};

const confirmPassword = ref("");
const isSamePassword = computed(
    () =>
        form.password == confirmPassword.value &&
        form.password != "" &&
        confirmPassword.value != ""
);

const isSubmitBtnDisabled = computed(
    () =>
        !isSamePassword.value ||
        score.value < 3
);

const score = ref(null);
const onScore = (payload) => {
    score.value = payload.score;
};

const router = useRouter();
const goBack = () => {
    router.go(-1);
};

const avatarLetter = computed(() => {
    return usersStore.users?.firstName?.charAt(0).toUpperCase() || '';
});

const fileInput = ref(null);
const openFileDialog = () => {
    if (fileInput.value) {
        fileInput.value.value = null;
        fileInput.value.click();
    }
};

const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (event) => {
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                }, file.type);
            };
        };

        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

const onFileChange = async (event) => {
    const file = event.target.files[0];

    const resizedFile = await resizeImage(file, 200, 200);

    await uploadProfilePicture(resizedFile);

    const reader = new FileReader();
    reader.onload = (e) => {
        usersStore.users.pictureFile = e.target.result;
    };
    reader.readAsDataURL(resizedFile);
};

const uploadProfilePicture = async (file) => {
    const userId = usersStore.users.id;
    const formData = new FormData();

    formData.append('UserId', userId);
    formData.append('File', file);

    loadingSpinner.value = true;
    try {
        const response = await axios.patch(`/api/users/${userId}/profilePicture`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            await setNewProfilePicture(response.data)
            toastObject.value.title = "Success!";
            toastObject.value.state = true;
            toastObject.value.message = "Profile picture updated successfully!";
            toastObject.value.color = "success";
            toastObject.value.icon = "fas fa-check fa-lg me-2";
        } else {
            toastObject.value.title = "Error!";
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during the request.";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        }
    } catch (error) {
        toastObject.value.title = "Error!";
        toastObject.value.state = true;
        toastObject.value.message = "An error occurred during the request: " + (error.response ? error.response.data : error);
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
    } finally {
        loadingSpinner.value = false;
    }
};

const setNewProfilePicture = async (userId) => {
    const res = await usersStore.getUserById(userId);
    usersStore.users = { ...usersStore.users, profilePictureUrl: res.profilePictureUrl };
    const userDetailStore = useUserDetailStore();
    userDetailStore.userDetail = { ...userDetailStore.userDetail, profilePictureUrl: res.profilePictureUrl };
}

const userType = (userTypeId) => {
    if (userTypeId === 1) {
        return "Admin";
    }
    if (userTypeId === 2) {
        return "User";
    }
    if (userTypeId === 3) {
        return "Distributor";
    }
    if (userTypeId === 4) {
        return "Super Admin";
    }
}

const socialMediaLinks = ref({
    1: usersStore.users.youtube,
    2: usersStore.users.twitch,
    3: usersStore.users.instagram,
    4: usersStore.users.tiktok,
    5: usersStore.users.facebook,
    6: usersStore.users.twitter,
    7: usersStore.users.douyin,
    8: usersStore.users.kuaishou,
    9: usersStore.users.rumble,
    10: usersStore.users.others,
});

const socialMedia = ref([
    { text: "YouTube", value: 1, icon: "youtube", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Twitch TV", value: 2, icon: "twitch", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Instagram", value: 3, icon: "instagram", iconStyle: "fab", marginRight: 'margin-right: 15px' },
    { text: "TikTok", value: 4, icon: "tiktok", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Facebook", value: 5, icon: "facebook", iconStyle: "fab", marginRight: 'margin-right: 13px' },
    { text: "Twitter/X", value: 6, icon: "x-twitter", iconStyle: "fa-brands", marginRight: 'margin-right: 8px' },
    { text: "Douyin", value: 7, icon: "tiktok", iconStyle: "fab", marginRight: 'margin-right: 8px' },
    { text: "Kuaishou", value: 8, icon: "youtube", iconStyle: "fab", marginRight: 'margin-right: 12px' },
    { text: "Rumble", value: 9, icon: "play", iconStyle: "fas", marginRight: 'margin-right: 12px' },
    { text: "Others", value: 10, icon: "globe", iconStyle: "fas", marginRight: 'margin-right: 8px' },
]);

watch(() => usersStore.users, (userSocialLinks) => {
    if (userSocialLinks) {
        socialMediaLinks.value = {
            1: userSocialLinks.youtube,
            2: userSocialLinks.twitch,
            3: userSocialLinks.instagram,
            4: userSocialLinks.tiktok,
            5: userSocialLinks.facebook,
            6: userSocialLinks.twitter,
            7: userSocialLinks.douyin,
            8: userSocialLinks.kuaishou,
            9: userSocialLinks.rumble,
            10: userSocialLinks.others,
        };
    }
});

const sendEmail = async () => {
    const subject = `${usersStore.users.firstName},${usersStore.users.lastName} - Update My Content`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-card {
    width: 80%;
}

.social-title {
    width: 25%;
}

.social-link {
    width: 75%;
}

@media screen and (max-width: 767px) {
    .social-card {
        width: 100%
    }

    .social-title {
        width: 45%;
    }

    .social-link {
        width: 55%;
    }
}

.default-avatar {
    width: 80px;
    height: 80px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 40px;
    font-weight: bold;
}

.profile-avatar {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}
</style>
